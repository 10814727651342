<template>
    <div class="recommend-page">
        <div class="banner vivify fadeIn" v-if="bannerList.length>0">
            <img :src="bannerList[0].banner">
        </div>
        <div class="inputBox">
          <div v-for="(item,index) in list" :key="index" class="tab_bar" :class="{tab_bar_item_btna:form.cid == item.id}" @click="TabbarClick(index,item)">
            {{ item.name }}
          </div>
        </div>
        <div class="contant"  v-if="tableData.length>0">
            <transition-group> <!-- 1️⃣使用 transition-group 标签包裹列表； -->
                <div class="contentBox" v-for="(item,index) in tableData" :key="index"  @click="details(item,index)">
                    <div class="imageBox">
                        <img class="image1" :src="item.image"/>
                    </div>
                    <div class="valueBox">
                        <div class="text1">{{ item.title_en }}</div>
                        <div class="text2">{{ item.title }} {{ item.title_en }} </div>
                        <div class="text3">
                            {{ item.desc }}
                        </div>
                    </div>
                </div>
            </transition-group>

        </div>
        <div v-else style="margin-bottom: 100px">
            <el-empty description="暂无数据" :image="require('./img/wuImage.png')" ></el-empty>
        </div>
        <el-pagination background @current-change="handleCurrentChange" :current-page.sync="form.page" :pager-count="7" :page-size="Number(searchData.limit)"
                       layout="prev, pager, next" :hide-on-single-page="true" :total="searchData.total_count">
        </el-pagination>
    </div>
</template>
<script>
  import '../assets/css/common.css'
  import '@/views/scss/recommended.scss'
  export default{
    name: "Recommended",
    data () {
      return {
        list:[],
          bannerList:[],
          form: {
              keywords: '',
              page: 1,
              limit: 5,
              cid:'',
          },
          tableData:[],
          searchData: [],
      }
    },
      created() {
          this.Interface()
      },
      mounted() {
          this.sortList()
      },
   methods: {
       /**
        * tab点击
        * */
       TabbarClick(index,item) {
           this.form.cid = item.id
           this.searchClick()
       },
       /**搜索按钮点击*/
       searchClick() {
           this.form.page = 1
           this.InterfaceList()
       },
       handleCurrentChange(val) {
           if (this.form.page) {
               this.form.page = val;
           }
           this.InterfaceList()
       },
       async Interface() {
           //公司信息
           await this.$axios.post('pcapi/index/banner',{
               type:3
           }).then(res => {
               if (res.data.code == 1) {
                   this.bannerList = res.data.data
               }
           })
       },
       async sortList() {
           //分类列表
           await this.$axios.post('pcapi/index/recommend_cate',{}).then(res => {
               if (res.data.code == 1) {
                   this.list = res.data.data
                   if(this.list.length>0) {
                       this.form.cid = this.list[0].id
                       this.InterfaceList()
                   }
               }
           })
       },
       async InterfaceList() {
           //产品列表
           await this.$axios.post('pcapi/index/recommend',{
               ...this.form
           }).then(res => {
               if (res.data.code == 1) {
                   this.searchData = res.data.data
                   this.tableData = res.data.data.list
               }
           })
       },
    }

  }
</script>
<style>

</style>
